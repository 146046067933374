import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const token = localStorage.getItem("lb_token");
const initialState = token
  ? {
      loggedIn: true,
      balance: null,
      // balanceBetflix: null,
      // balanceUFA: null,
      bank: null,
      banknumber: null,
      fname: null,
      lname: null,
      phone: null,
      truewallet: null,
      userBetflix: null,
      userUFA: null,
      promotion: null,
      promotion_turn: null,
      turnover: null,
      percent_withdraw: null,
    }
  : {
      loggedIn: false,
      balance: null,
      // balanceBetflix: null,
      // balanceUFA: null,
      bank: null,
      banknumber: null,
      fname: null,
      lname: null,
      phone: null,
      twnId: null,
      userBetflix: null,
      userUFA: null,
      promotion: null,
      promotion_turn: null,
      turnover: null,
      percent_withdraw: null,
    };

export default new Vuex.Store({
  state: {
    user: initialState,
    page: null,
    providers: [],
    providersCasino: [],
    providersAll: [],
    providersSlot: [],
    providersFishing: [],
    providersSport: [],
    webSetting: { lineId: null, telegId: null, logo: null, title: null, withdraw_min: null, withdrawtimemax: null, color1:null, url_affiliate:null, website_annoucetext:null },
  },
  getters: {
    Page: (state) => {
      return state.page;
    },
    Providers: (state) => {
      return state.providers;
    },
    ProvidersAll: (state) => {
      return state.providersAll;
    },
    ProvidersCasino: (state) => {
      return state.providersCasino;
    },
    ProvidersSlot: (state) => {
      return state.providersSlot;
    },
    ProvidersFishing: (state) => {
      return state.providersFishing;
    },
    ProvidersSport: (state) => {
      return state.providersSport;
    },
    Auth: (state) => {
      return state.user;
    },
    WebSetting: (state) => {
      return state.webSetting;
    },
  },
  mutations: {
    pageChange(state, page) {
      state.page = page;
    },
    saveProviders(state, providers) {
      state.providers = providers;

      let AllArr = providers;

      let sa = AllArr[51];
      AllArr[51] = AllArr[0];
      AllArr[0] = sa;

      let pgb = AllArr[49];
      AllArr[49] = AllArr[1];
      AllArr[1] = pgb;
      
      let pga = AllArr[44];
      AllArr[44] = AllArr[5];
      AllArr[5] = pga;

      let sexya = AllArr[37];
      AllArr[37] = AllArr[4];
      AllArr[4] = sexya;

      let jla = AllArr[22];
      AllArr[22] = AllArr[3];
      AllArr[3] = jla;

      state.providersAll = AllArr;

      let SportArr = providers.filter((el) => el.type.includes("sport") && el != null);

      state.providersSport = SportArr;

      let CasinoArr = providers.filter((el) => el.type.includes("casino"));

      // let jil = CasinoArr[12];
      // CasinoArr[12] = CasinoArr[1];
      // CasinoArr[1] = jil;

      // let jila = CasinoArr[8];
      // CasinoArr[8] = CasinoArr[0];
      // CasinoArr[0] = jila;

      state.providersCasino = CasinoArr;

      let SlotArr = providers.filter((el) => el.type.includes("slot"));

      let pg = SlotArr[40];
      SlotArr[40] = SlotArr[1];
      SlotArr[1] = pg;

      let amb = SlotArr[41];
      SlotArr[41] = SlotArr[2];
      SlotArr[2] = amb;

      let joker = SlotArr[42];
      SlotArr[42] = SlotArr[0];
      SlotArr[0] = joker;

      // let ep = SlotArr[1];
      // SlotArr[1] = SlotArr[0];
      // SlotArr[0] = ep;

      state.providersSlot = SlotArr;

      let FishingArr = providers.filter((el) => el.type.includes("fishing"));
      state.providersFishing = FishingArr;
    },
    loginAuth(state, user) {
      state.user.loggedIn = true;
      localStorage.setItem("lb_token", user.token);
    },
    logoutAuth(state) {
      state.user.loggedIn = false;
      state.user.phone = null;
      state.user.fname = null;
      state.user.lname = null;
      state.user.bank = null;
      state.user.banknumber = null;
      state.user.truewallet = null;
      state.user.balance = null;
      state.user.turnover = null;
      state.user.promotion_turn = null;
      state.user.promotion = null;
      state.user.promotion_detail = null;
      state.user.promotion_wallet = null;
      state.user.percent_withdraw = null;
      state.user.count = null;
      state.user.amount = null;
      localStorage.removeItem("lb_token");
      window.location.replace("../");
      // loggedIn: true,
      // balance: null,
      // balanceBetflix: null,
      // balanceUFA: null,
      // bank: null,
      // banknumber: null,
      // fname: null,
      // lname: null,
      // phone: null,
      // twnId: null,
      // userBetflix: null,
      // userUFA: null,
      // promotion: null,
      // promotionTurn: null,
      // turnover: null,
      // percent_withdraw: null,
    },
    saveProfile(state, profile) {
      state.user.phone = profile.phone;
      state.user.fname = profile.fname;
      state.user.lname = profile.lname;
      state.user.bank = profile.bank;
      state.user.banknumber = profile.banknumber;
      state.user.truewallet = profile.truewallet;
      state.user.balancenodigit = profile.balance;
      state.user.balance = (Math.round(profile.balance * 100) / 100).toFixed(2);
      state.user.turnover = profile.turnover;
      state.user.promotion_turn = profile.promotion_turn;
      state.user.promotion = profile.promotion;
      state.user.promotion_detail = profile.promotion_detail;
      state.user.promotion_wallet = profile.promotion_wallet;
      state.user.promotion_withdrawmax = profile.promotion_withdrawmax;
      state.user.percent_withdraw = parseFloat(
        profile.balance + (profile.percent_withdraw / 100) * profile.balance
      ).toFixed(2);
    },
    savePageSetting(state, setting) {
      state.webSetting.lineId = setting.line_id;
      state.webSetting.telegId = setting.teleg_id;
      state.webSetting.logo = setting.logo;
      state.webSetting.title = setting.title;
      state.webSetting.withdraw_min = setting.withdraw_min;
      state.webSetting.withdrawtimemax = setting.withdrawtimemax;
      state.webSetting.bgpicture = setting.bgpicture;
      state.webSetting.color1 = setting.color1;
      state.webSetting.website_name = setting.website_name;
      state.webSetting.url_affiliate = setting.url_affiliate;
      state.webSetting.website_annoucetext = setting.website_annoucetext;
    },
    saveSpin(state, spin) {
      state.user.multiplier = spin.multiplier;
      state.user.reward_credit = spin.reward_credit;
    },
    saveTurnover(state, turnover) {
      state.user.turnover = turnover;
    },
  },
  actions: {
    pageChange(context, page) {
      context.commit("pageChange", page);
    },
    saveProviders(context, providers) {
      context.commit("saveProviders", providers);
    },
    loginAuth(context, user) {
      context.commit("loginAuth", user);
    },
    logoutAuth(context) {
      context.commit("logoutAuth");
    },
    saveProfile(context, profile) {
      context.commit("saveProfile", profile);
    },
    savePageSetting(context, setting) {
      context.commit("savePageSetting", setting);
    },
    saveTurnover(context, turnover) {
      context.commit("saveTurnover", turnover);
    },
  },
  modules: {},
});
